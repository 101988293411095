

/* Hide the default scrollbar */
.weather-container::-webkit-scrollbar {
  display: none;
}

/* Add a custom scrollbar track */
.weather-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

/* Add a custom scrollbar thumb */
.weather-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

/* Show the scrollbar only when hovering over the container */
.weather-container:hover::-webkit-scrollbar {
  display: block;
}

.report-title{
  margin-bottom: 38px;
  margin: auto;
  justify-content: center;
}

.report-page{
  margin: auto;
  justify-content: center;
  align-items: center;
}
